document.addEventListener('DOMContentLoaded', () => {
  const inputField = document.querySelector('.site-footer__search-input');
  const submitButton = document.querySelector('.site-footer__search-submit');

  // Function to toggle button disabled state
  const toggleButtonState = () => {
    submitButton.disabled = inputField.value.trim() === '';
  };

  // Initial check
  toggleButtonState();

  // Event listener for input changes
  inputField.addEventListener('input', toggleButtonState);
});
